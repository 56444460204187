body {
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}
.ck-editor__editable {
  min-height: 300px;
  /* min-width: 760px; */
}
/*Toolbar*/
.text-black {
  color: #000;
}

.text-inactive {
  color: #acafaf;
}

.cursor-pointer {
  cursor: pointer;
}

.loading {
  display: grid;
  place-items: center;
}

.text-brown {
  color: #4a3122;
}

.bg-brown {
  background-color: #4a3122;
}
.bg-brown:hover {
  background-color: #4a3122;
}
.toast-checklist {
  color: white;
  width: 24px;
  height: 24px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}

.toast-checklist-success {
  background: #3faa8f;
}

.toast-checklist-failed {
  background: red;
}

.margin-left-2 {
  margin-left: 0.5rem;
}

.me-1rem {
  margin-right: 1.2rem !important;
}

.me-1koma4rem {
  margin-right: 1.4rem !important;
}

.action-icon {
  background: #ff3737;
  color: white;
  width: 36px;
  height: 36px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.dashboard-card {
  height: 7.2rem;
  max-width: 18rem;
  border: 1px solid #ae915e;
  border-radius: 5px;
}

.dashboard-satisfaction-square {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;
}
.ml-03rem {
  margin-left: 0.3rem;
}
.text-1rem {
  font-size: 1rem;
}

.bg-gray-200 {
  background-color: #f4f4f4;
}

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Choose a file';
  color: white;
  display: inline-block;
  background: #be9f56;
  border-radius: 3px;
  padding: 8px 15px;
  outline: none;
  white-space: nowrap;
  /* -webkit-user-select: none; */
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* hide scrollbar but allow scrolling */
.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.border-nav {
  border-bottom: 2px solid #ae915e;
  padding-bottom: 1rem;
}

.absolute {
  position: absolute;
}

.right-0 {
  right: 0;
}

.font-bold {
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
