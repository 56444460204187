// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';

.formselector-md {
  .card {
    width: 175px;
  }
}

.formCard-md {
  width: 175px;
  height: 39px;
  padding: 10px;

  text-align: center;

  border: 1px solid #be9f56;
  box-sizing: border-box;
  border-radius: 3px;

  font-size: 14px;

  p {
    margin-top: -5px;
  }
}

.formselector-sm {
  .card {
    width: 140px;
  }
}

.formCard-sm {
  width: 140px;
  height: 39px;
  padding: 10px;

  text-align: center;

  border: 1px solid #be9f56;
  box-sizing: border-box;
  border-radius: 3px;

  font-size: 14px;

  p {
    margin-top: -5px;
  }
}

.formselector-lg {
  .card {
    width: 230px;
  }
}

.formCard-lg {
  width: 230px;
  height: 39px;
  padding: 10px;

  text-align: center;

  border: 1px solid #be9f56;
  box-sizing: border-box;
  border-radius: 3px;

  font-size: 14px;

  p {
    margin-top: -5px;
  }
}

// hiding pagination
.sc-ezbkAF {
  button {
    display: none;
  }
}

.switcherButtonContainer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  .switcherButton {
    border-bottom: 3px solid #be9f56;
  }
}

.word-wrap {
  word-wrap: break-word;
}

.form-component-select {
  width: 300px;
  height: 58px;
  background: #898989;
  border-radius: 3px;
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;

  P {
    margin: 0;
  }
}

.rec-sm {
  width: 26px;
  height: 26px;
  background: #ffffff;
  border-radius: 2px;
}

.img-patient-rec {
  width: 128px;
  height: 138px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

.interaction-component {
  width: 511px;
  height: 34px;

  background: #be9f56;
  border-radius: 77px;

  .interaction-bar {
    width: 5px;
    height: 34px;
    background: #c4c4c4;
    margin-left: 215px;
  }
}

.timeline-component {
  width: 511px;
  height: 34px;
  background: #be9f56;
  border-radius: 77px;
  .timeline-bar {
    width: 69px;
    height: 34px;
    background: #c4c4c4;
    margin-left: 215px;
  }
}

.custom-file-upload {
  border: 1px solid #ccc;
  background-color: #be9f56;
  color: white;
  text-align: center;
  cursor: pointer;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  margin-left: 318px;
  margin-top: 186px;
}

.generalinformation {
  font-size: 14px;
}

.box-living-matrix {
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  width: 291px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
}

.input-eliminate:focus {
  outline: none !important;
  border-color: none;
  box-shadow: none;
}

.btn-category {
  width: 147px;
  height: 50px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
}

.btn-category-2 {
  width: 226px;
  height: 50px;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
}

.custom-file-upload-text {
  margin-left: 220px;
  margin-top: 16px;
  font-size: 14px;
}

.card-notification {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.card-notification-bg-grey {
  background-color: rgba(196, 196, 196, 0.44);
}

.popover {
  width: 320px;
  max-width: none;
}

.btn-save-msq {
  height: 30;
  color: 'white';
  border: 'none';
  background-color: '#BE9F56';
  padding-left: 20;
  padding-right: 20;
  border-radius: 4;
}
